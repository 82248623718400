@use "Theme_constants" as theme;

html {
    height: 100%;
    box-sizing: border-box;
}

* {
    box-sizing: inherit;

    &:before {
        box-sizing: inherit;
    }

    &:after {
        box-sizing: inherit;
    }
}

body {
    position: relative;
    margin: 0;
    padding-top: 105px;
    padding-bottom: 200px;
    font-family: "Open Sans", sans-serif;
    font-size: theme.$font-medium;
    color: theme.$text-color;
    min-height: 100%;
    background-color: theme.$background-grey;
    overflow-x: hidden;

    ::-webkit-scrollbar {
        display: none;
        /* Chrome, Safari and Opera */
    }

    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.loading {
    padding: 50px;
    text-align: center;
}

a {
    color: theme.$text-color;
    transition: 0.5s;
    text-decoration: none;

    &:hover {
        color: theme.$base-color;
        font-weight: bold;
    }
}

label {
    font-weight: bold;
}

h1 {
    margin: 0;
    font-size: 46px;
}

.main-navigation {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    overflow: hidden;
    transition: 0.5s;
    overflow: visible;
    z-index: 1;

    img {
        padding: 25px 10px !important;
        width: 210px;
        height: auto;
        margin: 0 auto;
    }

    > div:first-child,
    > div:last-child {
        flex: 1; /* Take up equal space */
    }

    > div:first-child {
        margin-left: 15px;
    }

    > div:last-child {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;

        a {
            text-align: center;
            padding-right: 20px;
        }

        .pages {
            padding: 10px;
            @media screen and (max-width: 750px) {
                display: none !important;
                visibility: hidden;
            }
        }
    }
}

.home-image {
    background-image: url("../assets/images/img02132023_004_color.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
    margin: 0 50px;
}

footer {
    position: absolute;
    height: 200px;
    margin: 0 50px;
    right: 0;
    bottom: 0;
    left: 0;

    .footer-content {
        display: flex;
        flex-direction: column;
        padding: 50px 25px;
        gap: 15px;
        vertical-align: top;
        background-color: white;
        align-items: center;

        .pages {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }

        .footer-logo {
            img {
                width: 200px;
                height: auto;
            }

            text-align: center;
        }
    }
}

.divider {
    border-top: 2px solid theme.$base-color;
    width: 150px;
    margin: auto;

    &.thick {
        border-top-width: 5px !important;
    }
}

.sub-info-left-border {
    padding-left: 20px;
    border-left: 2px solid theme.$base-color;

    &.add-margin {
        margin: 0 0 20px 25px;
    }
}

.home-bio-panel {
    text-align: center;
    margin: -65px 125px 50px 125px;
    padding: 10px 15px 30px 15px;
    background-color: white;
}

.panel {
    margin: 25px;

    .header-panel {
        padding: 25px 25px 20px 25px;
    }

    img {
        display: block;
        max-width: 100%;
    }

    .see-more-btn {
        display: table;
        margin: 0 auto;
    }

    .gallery-header-panel {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
    }

    .gallery {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 15px;
    }

    .gallery-content {
        padding: 20px;
        align-items: center;
        text-align: center;

        .content {
            text-align: center;

            .art-type {
                margin-top: -20px;
                font-size: theme.$font-small;
                color: theme.$base-color;
            }
        }

        .art {
            display: flex;
            justify-content: center;

            img {
                width: auto;
                max-height: 300px;
                border-radius: theme.$border-radius;
                filter: blur(0.1px) !important;
                -webkit-filter: blur(0.1px);
            }
        }

        p {
            width: 75%;
            margin: 17px auto;
        }

        button {
            margin: 15px auto;
        }
    }
}

input[type="password"] {
    border: 1px solid theme.$border-color;
    border-radius: theme.$border-radius;
    height: 40px;
    margin: 20px auto;
    padding: 5px;
    font-family: "Open Sans", sans-serif;
    font-size: theme.$font-medium;
    width: 100%;
    outline: none;

    &:focus {
        outline: none;
    }
}

button {
    backface-visibility: hidden;
    background: theme.$base-color;
    border: 0;
    border-radius: theme.$border-radius;
    height: 40px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: theme.$font-medium;
    letter-spacing: -.01em;
    line-height: 1.3;
    padding: 8px 10px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transform: translateZ(0) scale(1);
    transition: transform .2s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin: auto;

    &:disabled {
        background: theme.$base-grey !important;
        color: #787878;
        cursor: auto;
    }

    &:not(:disabled):hover {
        transform: scale(1.05);
    }

    &:not(:disabled):hover:active {
        transform: scale(1.05) translateY(.125rem);
    }

    &:focus {
        outline: 0 solid transparent;
    }

    &:focus:before {
        border-width: .125rem;
        content: "";
        left: calc(-1*.375rem);
        pointer-events: none;
        position: absolute;
        top: calc(-1*.375rem);
        transition: border-radius;
        user-select: none;
    }

    &:focus:not(:focus-visible) {
        outline: 0 solid transparent;
    }

    &:not(:disabled):active {
        transform: translateY(.125rem);
    }
}

.cancel-btn {
    background: transparent !important;
    border: 1px solid theme.$base-color !important;
    color: theme.$base-color !important;
}

.pagination {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 60px;
    justify-content: center;
    list-style-type: none;
    position: relative;
    width: 100%;
    padding: 0;
    padding-bottom: 10px;

    li {
        width: 40px;
        text-align: center;
    }

    a {
        transition: 0.5s;

        &:hover {
            cursor: pointer;
        }
    }

    .item {
        align-items: center;
        cursor: pointer;
        display: flex;
        height: 40px;
        justify-content: center;
        transition: 0.5s;

        a {
            font-weight: bold;
        }

        i {
            font-size: theme.$font-large;
            transition: 0.5s;
        }
    }

    .active {
        text-decoration: underline;
        transition: 0.5s;
        color: theme.$base-color !important;

        a {
            color: theme.$base-color !important;
        }
    }

    .next,
    .previous {
        position: absolute;
        width: 50px;
        transition: 0.5s;

        &:hover {
            background-color: unset;
        }
    }

    .next {
        right: 0;
    }

    .previous {
        left: 0;
    }

    .disabled i {
        color: theme.$base-grey !important;

        &:hover {
            color: theme.$base-grey !important;
        }
    }
}

._37_FJ {
    margin: 6em auto;
    text-align: center;
    width: 50%;
    padding: 25px;
    background-color: white;
}

.popup-overlay {
    background: rgba(0, 0, 0, 0.3);
}

.popup {
    background-color: white;
    padding: 20px;
    margin: 10px;
    border-radius: theme.$border-radius;

    .popup-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button {
            margin: unset !important;
        }
    }

    .popup-error {
        color: red;
        padding-top: 10px;
    }

    p {
        text-wrap: wrap;
        max-width: 550px;
        min-width: 200px;
    }
}

.key-trigger {
    position: sticky;
    text-align: right;
    bottom: 5px;
    margin: 7px;
    z-index: 1;

    i {
        transition: 0.5s;

        &:hover {
            cursor: pointer;
            color: theme.$base-color;
        }
    }
}

.about-content {
    display: block;

    .collage {
        padding: 0 25px;
    }

    .content {
        padding: 10px 25px 30px 25px;
    }
}

.collage {
    h3 {
        text-align: center;
    }

    .row {
        display: -ms-flexbox;
        /* IE10 */
        display: flex;
        -ms-flex-wrap: wrap;
        /* IE10 */
        flex-wrap: wrap;
        padding: 0 5px;

        /* Create four equal columns that sits next to each other */
        .column {
            -ms-flex: 25%;
            /* IE10 */
            flex: 25%;
            max-width: 25%;
            padding: 0 7px;

            img {
                margin-top: 14px;
                vertical-align: middle;
                width: 100%;
            }
        }
    }

    /* Responsive layout - makes a two column-layout instead of four columns */
    @media screen and (max-width: 800px) {
        .column {
            -ms-flex: 50%;
            flex: 50%;
            max-width: 50%;
        }
    }

    /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
    @media screen and (max-width: 600px) {
        .row {
            padding: 0 3px !important;

            .column {
                -ms-flex: 100%;
                flex: 100%;
                max-width: 100%;
                padding: 0 3px !important;
            }
        }
    }
}

@media screen and (max-width: 625px) {
    .gallery {
        display: flex !important;
        flex-direction: column;

        .art img {
            max-height: unset !important;
        }
    }

    ._37_FJ {
        width: 70% !important;
    }

    .panel {
        margin: 10px !important;
    }

    .home-bio-panel {
        margin: -65px 40px 50px 40px !important;
    }

    .home-image,
    footer {
        margin: 0 30px !important;
    }

    footer {
        .donate {
            display: none !important;
            visibility: hidden;
        }
    }
}

@media screen and (max-width: 800px) {
    .about-content {
        flex-direction: column !important;

        .content {
            flex: unset !important;
            max-width: unset;
        }
    }
}

@media screen and (max-width: 900px) {
    footer {
        .footer-logo {
            .footer-desc {
                padding: 18px 15px 5px 15px !important;
            }
        }
    }
}