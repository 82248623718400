@use "Theme_constants" as theme;

.hamburger-menu {
    position: relative;
    cursor: pointer;
    display: none !important;
    visibility: hidden;

    button {
        background: none;
        color: theme.$text-color;
        font-size: theme.$font-xxl;
        padding: 10px 25px;
        transition: 0.5s;

        &:hover {
            color: theme.$base-color;
        }
    }

    @media screen and (max-width: 750px) {
        display: inline-block !important;
        visibility: visible;
    }
}

.dropdown {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 25px;
    width: 125px;
    background-color: white;
    border-radius: theme.$border-radius;
    transition: 0.5s;

    a {
        padding: 15px 20px !important;
        text-decoration: none;

        &:hover {
            color: theme.$base-color;
            font-weight: bold;
        }
    }
}