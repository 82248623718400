// colors
$base-color: #823794;
$text-color: #2D3033;
$border-color: #aaa;
$base-grey: #ccc;
$background-grey: #f2f2f2;

// font sizes
$font-small: 14px;
$font-medium: 17px;
$font-large: 20px;
$font-xxl: 25px;

//border
$border-radius: 2px;